import React from "react";
import { VictoryBar, VictoryChart, VictoryTheme } from "victory";

const ChartComponent = ({ data, title }) => {
	return (
		<div
			className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-4 sm:p-6 border border-gray-200 dark:border-gray-700 transition-all duration-300 hover:shadow-xl"
			style={{ height: "300px" }}
		>
			{/* Adjust container height */}
			<h3 className="text-lg font-semibold text-gray-700 dark:text-gray-200 mb-4">
				{title}
			</h3>
			<VictoryChart
				theme={VictoryTheme.material}
				domainPadding={{ x: 30 }}
				height={200}
				style={{
					parent: { maxWidth: "100%" },
				}}
			>
				<VictoryBar
					data={data}
					style={{
						data: {
							fill: ({ datum }) => datum.fill,
							strokeWidth: 2,
							borderRadius: 5,
						},
						labels: {
							fill: "gray",
							fontSize: 12,
						},
					}}
				/>
			</VictoryChart>
		</div>
	);
};

export default ChartComponent;
