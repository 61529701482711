import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	domain,
	vendors,
} from "../../../../authentication/store/actions/index";
import { useSelector, useDispatch } from "react-redux";
import http from "../../../../resources/http";
import { baseURL } from "../../../../resources/apiClient";
import LoadingSpinner from "../../../../utils/LoadingSpinner";

const VendorDetailsBrief = ({ name, showHide }) => {
	const [data, setData] = useState([]);
	const dispatch = useDispatch();
	const processId = useSelector((state) => state.pid);
	let type = JSON.parse(localStorage.getItem("cyber-minds"));
	let clientID = type.user.client;
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		http
			.get(
				`${baseURL}/excel-upload/assets?clientId=${clientID}&processId=${processId}`
			)
			.then((response) => {
				setData(response.data);
				setLoading(true);
			})
			.then(
				(response) => {},
				(err) => {
					console.log("No Data To Show");
				}
			)
			.catch((err) => {
				return false;
			});
	}, []);
	console.log(data, "data");

	var uniqueVendors = data.reduce((unique, o) => {
		if (!unique.some((obj) => obj.vendors === o.vendors)) {
			unique.push(o);
		}
		return unique;
	}, []);
	const DisplayData = uniqueVendors.map((risk) => {
		return (
			<div
				className="link_hover pb-2"
				onClick={() => {
					dispatch(vendors(risk.vendors));
					dispatch(domain(risk.vendors.toLowerCase() + ".com"));
				}}
			>
				<Link
					className=" link_hover text-lg font-semibold text-gray-700 dark:text-gray-200"
					to="/company-profile"
				>
					{risk.vendors}
				</Link>
			</div>
		);
	});
	return <div>{DisplayData}</div>;
};

export default VendorDetailsBrief;
